import { ChangeDetectionStrategy, Component, EventEmitter, inject, Output } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ButtonComponent } from '@app/shared/ui-components/button/button.component';
import { AbstractCookiesComponent } from '@bc-common-lib';
import { environment } from '@env/environment';
import { CookieSettingsComponent } from './cookie-settings/cookie-settings.component';

@Component({
  selector: 'us2-cookies',
  standalone: true,
  imports: [
    MatDialogModule,
    ButtonComponent
],
providers: [ { provide: MatDialogRef, useValue: {} }, ],
  templateUrl: './cookies.component.html',
  styleUrls: [ './cookies.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CookiesComponent extends AbstractCookiesComponent {
  @Output() cookiesAccepted = new EventEmitter<string[]>();
  component = CookieSettingsComponent;
  cookiePolicyUrl = environment.cookiePolicy;
  
  constructor() {
    super();
    this.dialogRef = inject(MatDialogRef<CookiesComponent>)
  }

}
