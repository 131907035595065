import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { JwtService, transformData } from '@bc-core-lib';
import { camelCase } from 'lodash-es';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export const CoreInterceptor: HttpInterceptorFn = (req, next) => {
  const jwtService = inject(JwtService);

  const token = jwtService.validTokenString;

  //const update = token ? { setHeaders: { Authorization: `Bearer ${token}` } } : {};

  // Clone the request and add the authorization header
  const request = req.clone({
    setHeaders: {
      Authorization: `Bearer ${token}`
    }
  });

  // Pass the cloned request with the updated header to the next handler
  return next(request)
    .pipe(
      catchError(resp => {
        if (resp instanceof HttpErrorResponse) {
          if (resp.error) {
            resp.error.errors = transformData(resp.error.errors, camelCase);
          }
        }

        return throwError(resp);
      }),
    );
};

