import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MobileLayoutGuard  {
  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkLayout(state);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkLayout(state);
  }

  private checkLayout(state: RouterStateSnapshot): boolean {
    const isHandset = this.breakpointObserver.isMatched(Breakpoints.Handset);

    if (isHandset) {
      this.router.navigate([ 'mobile-placeholder' ]);

      return false;
    }

    return true;
  }
}
