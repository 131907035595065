import { isPlatformServer } from '@angular/common';
import { Component, computed, Inject, inject, OnInit, PLATFORM_ID, Signal } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterLink, RouterOutlet } from '@angular/router';
import { ProductService, StoreService, WebsiteService } from '@bc-core-lib';
import { SaleService } from '@bc-core/api-services';
import { ApiKeyService } from '@bc-core/services';
import { BreakpointService } from '@shared/services/breakpoint.service';
import { CustomCookieService } from '@shared/services/custom-cookie.service';
import { InitDataService } from '@shared/services/init-data.service';
import { ProgressSpinnerComponent } from '@shared/ui-components';
import { TuiRootModule } from '@taiga-ui/core';
import { CookiesComponent } from './core/cookies/cookies.component';


@Component({
  selector: 'us2-root',
  standalone: true,
  imports: [
    TuiRootModule,
    RouterOutlet,
    CookiesComponent,
    ProgressSpinnerComponent,
    RouterLink,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  title = 'usersite2';
  hideCookiesBanner: Signal<boolean>;
  dataLoaded: Signal<boolean>;
  //websiteNotPublishedYet: boolean;
  storeService = inject(StoreService);
  saleService = inject(SaleService);
  productService = inject(ProductService);

  #initDataService = inject(InitDataService);
  #breakpointService = inject(BreakpointService);
  websiteService = inject(WebsiteService);

  // todo
  apiKeyService = inject(ApiKeyService);

  index = 0;

  readonly items = [
    { title: 'First', content: 'First content' },
    { title: 'Title #2', content: 'Much more content here so the height is bigger' },
    { title: 'Title III', content: 'Small item again' },
    { title: 'Title four', content: 'Relatively ling content here' },
    { title: 'Fifth item', content: 'Tiny text' },
    // eslint-disable-next-line @typescript-eslint/quotes
    { title: '6', content: "That one's short too" },
    { title: 'Lucky 7', content: 'This takes about two lines or so' },
    { title: 'Eighth card', content: 'Almost the last one' },
    { title: 'X', content: 'This is the longest item there is in this list' },
  ];

  #matIconReg = inject(MatIconRegistry);

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly sanitizer: DomSanitizer,
    private cookieService: CustomCookieService,
  ) {
    //this.#appService.loginDialog = LoginDialogComponent;

    this.initIcons();

    this.dataLoaded = computed(() => this.storeService.currentLoaded() || !this.websiteService.current()?.isEnabled);

    this.hideCookiesBanner = computed(() =>
      this.cookieService.hasAcceptedCookies() || this.#breakpointService.isMobile()
    );
  }

  ngOnInit(): void {
    this.#matIconReg.setDefaultFontSetClass('material-symbols-outlined');

    this.#initDataService.initWebsite();

    // todo
    //this.saleService.loadList();
    //this.productService.loadList();
  }

  private initIcons(): void {
    const domain = isPlatformServer(this.platformId) ? 'http://local.boxcommerce.com:4204/' : '';

    const defaultPackUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${domain}assets/icons/default.svg`);
    const flagPackUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${domain}assets/icons/flag.svg`);
    const iconContactPackUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${domain}assets/icons/icon-contact.svg`);
    const paymentAndPartnersPackUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${domain}assets/icons/payment-and-partners.svg`);

    this.matIconRegistry.addSvgIconSetInNamespace('d', defaultPackUrl);
    this.matIconRegistry.addSvgIconSetInNamespace('flag', flagPackUrl);
    this.matIconRegistry.addSvgIconSetInNamespace('ic', iconContactPackUrl);
    this.matIconRegistry.addSvgIconSetInNamespace('pnp', paymentAndPartnersPackUrl);

    // todo temporary solution
    const registerIcon = (iconName: string) =>
      this.matIconRegistry.addSvgIcon(
        iconName,
        this.sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/${iconName}.svg`)
      );

    [ 'stepper_tail', 'location-circle' ].forEach(registerIcon);
  }

  onCookiesAccepted(cookies: string[]): void {
    this.cookieService.setCookies(cookies);
  }
}
