import { Routes } from '@angular/router';
import { MobileLayoutGuard } from '@app/core/shared/mobile-layout.guard';
import { authGuard } from '@bc-core-lib';
import { websiteGuard } from '@shared/guards/website.guard';
import { ComingSoonGuard } from './core/coming-soon.guard';
import { ComingSoonComponent } from './core/coming-soon/coming-soon.component';

export const routes: Routes = [
  {
    path: '',
    canActivate: [ websiteGuard ],
    children: [
      {
        path: 'mobile-placeholder',
        loadComponent: () => import('./store-main/mobile-placeholder-page/mobile-placeholder-page.component').then(c => c.MobilePlaceholderPageComponent),
      },
      {
        path: '',
        canActivate: [ MobileLayoutGuard ],
        canActivateChild: [ MobileLayoutGuard ],
        children: [
          {
            path: '',
            loadChildren: () => import('./store-main/routes'),
          },
          {
            path: '',

            children: [
              {
                path: 'account',canActivate: [ authGuard ],
                loadChildren: () => import('./user-account/routes'),
              },
              {
                path: 'checkout',
                loadChildren: () => import('./checkout/routes'),
              },
            ]
          },
        ]
      }
    ],
  },
  {
    path: 'coming-soon',
    canActivate: [ ComingSoonGuard ],
    component: ComingSoonComponent
  },
  { path: '**', redirectTo: '' },
];
