<div class="rect layer layer-bottom"></div>

<div class="page-container flex flex-row">
  <div class="flex flex-col gap-12 justify-center">
    <div class="header layer layer-top">
      <img class="logo" src="/assets/images/logo-dark.svg" alt="Logo" i18n-alt />
    </div>

    <div class="title">
      <ng-container i18n>Website coming soon</ng-container>
    </div>
    
    <div class="description">
      <ng-container i18n><b>Oops, you're too fast!</b> We're still busy constructing our awesome website for you.
        Please come back soon to see what exciting content we will have lined up!
      </ng-container>
    </div>
    <img src="/assets/images/curved-line.svg" class="curved-line" alt="Divider image" i18n-alt />
  </div>

  <img class="coming-soon-img" src="/assets/images/coming-soon.svg" alt="Coming soon image" i18n-alt />

</div>