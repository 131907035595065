import { Injectable, signal } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

const COOKIES = 'cookies';


@Injectable({
  providedIn: 'root'
})
export class CustomCookieService {
  hasAcceptedCookies = signal(false);

  constructor(private cookieService: CookieService) {
      this.hasAcceptedCookies.set(this.cookieService.check(COOKIES));
  }

  setCookies(cookies: string[]): void {
    this.cookieService.set(COOKIES, JSON.stringify(cookies), 365);
    this.hasAcceptedCookies.set(true);
  }
}
