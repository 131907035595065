<form [formGroup]="form">

  <us2-dialog-container [config]="{}">

    <div class="store-info flex flex-col gap-5 items-center">
      <img class="logo-image" src="/assets/images/logo-dark.svg" alt="9to9 Logo" i18n-alt />

      <div class="title" i18n>Cookie policy</div>

      <div class="description text-center" i18n>
        We use 'cookies' to collect information about you and your activity across our site. A cookie is a small piece
        of data that our website stores on your computer, and accesses each time you visit, so we can understand how
        you use our site. This helps us serve you content based on preferences you have specified.
        <a [href]="cookiePolicyUrl" target="_blank"><b>More information.</b></a>
      </div>

    </div>

    <button us2-button color="primary" (click)="acceptAllAndClose()">Allow all</button>

    <div class="flex flex-col gap-3.5">
      <div class="subtitle uppercase" i18n>Manage consent preferences</div>

      <div class="cookies">

        <ng-container formArrayName="cookies">

          @for (cookie of cookies; let i = $index; track cookie.type) {

            <mat-expansion-panel class="checkbox-wrapper" hideToggle>

              <mat-expansion-panel-header class="flex flex-row justify-between">

                <mat-panel-title class="title flex flex-row items-between gap-3.5">
                  <div class="plusminus" [class.active]="cookie.expanded"></div>
                  <div>{{ cookie.title }}</div>
                </mat-panel-title>

                <mat-panel-description class="justify-end">

                  @if (cookie.canBeSwitchedOff) {

                    <mat-slide-toggle class="us2-slide-toggle" (click)="$event.stopPropagation()"
                      [formControlName]="i"></mat-slide-toggle>

                  } @else {

                    <div i18n class="label">Always active</div>

                  }

                </mat-panel-description>

              </mat-expansion-panel-header>

              <div class="description" [innerHTML]="cookie.description"></div>

            </mat-expansion-panel>
          }

        </ng-container>

      </div>

    </div>

    <div us2-dialog-actions class="actions flex flex-row gap-3 w-full">

      <button us2-button stroked (click)="onClose()">
        <ng-container i18n>Cancel</ng-container>
      </button>

      <button us2-button type="button" color="primary" (click)="onSubmit()">
        <ng-container i18n>Confirm</ng-container>
      </button>

    </div>

  </us2-dialog-container>

</form>