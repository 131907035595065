import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'us2-coming-soon',
  standalone: true,
  imports: [

  ],
  templateUrl: './coming-soon.component.html',
  styleUrls: [ './coming-soon.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComingSoonComponent implements OnInit {
  constructor() {
  }

  ngOnInit(): void {
  }

}
