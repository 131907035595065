import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AbstractCookiesComponent } from '@bc-common-lib';
import { ButtonComponent } from '@shared/ui-components/button/button.component';
import { DialogContainerComponent } from '@shared/ui-components/dialog-container/dialog-container.component';
import { environment } from '@env/environment';

@Component({
  selector: 'us2-cookie-settings',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatExpansionModule,
    MatSlideToggleModule,
    DialogContainerComponent,
    ButtonComponent,
  ],
  templateUrl: './cookie-settings.component.html',
  styleUrls: ['./cookie-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CookieSettingsComponent extends AbstractCookiesComponent {
  cookiePolicyUrl = environment.cookiePolicy;

  constructor() {
    super();
    this.dialogRef = inject(MatDialogRef<CookieSettingsComponent>)
  }
}
