import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withComponentInputBinding, withInMemoryScrolling } from '@angular/router';
import { AppService as AppServiceLib } from '@bc-core-lib';
import { AppConfig, BOX_APP_CONFIG } from '@bc-core/common-components.config';
import { ValidationMessageService } from '@bc-libs/core';
import { environment } from '@env/environment';
import { AppService } from '@shared/services/app.service';
import { TUI_VALIDATION_ERRORS } from '@taiga-ui/kit';

import { routes } from './app.routes';
import { CoreInterceptor } from './core/core.interceptor';

const boxConfig: AppConfig = {
  apiUrl: environment.apiCoreUrl,
  environment,
  paletteClasses: {
    // todo send class not color? (using color inside stepper component for now)
    primary: {
      background: 'us2-primary-background',
      text: 'us2-primary-text',
    },
  },
  paletteColors: {
    primary: '#ff6a00',
  }
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withComponentInputBinding(), withInMemoryScrolling({ scrollPositionRestoration: "top" })),
    //provideClientHydration(),
    provideHttpClient(withInterceptors([ CoreInterceptor ])),
    provideAnimations(),
    importProvidersFrom(MatDialogModule),
    { provide: AppServiceLib, useClass: AppService },
    { provide: BOX_APP_CONFIG, useValue: boxConfig },
    {
      provide: TUI_VALIDATION_ERRORS,
      useFactory: (service: ValidationMessageService) => service.messages2,
      deps: [ ValidationMessageService ],
    },
  ],
};
