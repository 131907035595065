import { inject } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { CanActivateFn, Router } from '@angular/router';
import { Website, WebsiteService } from '@bc-core-lib';
import { filter, map } from 'rxjs/operators';


export const ComingSoonGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const websiteService = inject(WebsiteService);

  const website$ = websiteService.current() ? toObservable(websiteService.current) : websiteService.loadCurrent();
  
  return website$
    .pipe(
      filter(website => !!website),
      map((website: Website) => {
        if (website?.isEnabled) {
          return router.createUrlTree([ 'catalog' ]);
        }
        
        return true;
      }),
    );
}
