<div class="cookies-container w-full fixed bottom-0">

  <div class="container flex flex-row gap-x-7">

    <div i18n class="description">
      This website utilizes cookies to enhance your browsing experience, improve site quality, and facilitate
      navigation. For further information, please refer to our
      <a [href]="cookiePolicyUrl" target="_blank"><b>Cookie Policy.</b></a>
    </div>

    <div class="button-container flex flex-row gap-x-3.5">

      <button us2-button stroked type="button" (click)="settings()" i18n>Cookie settings</button>

      <button us2-button color="primary" (click)="acceptAllAndEmit()">Accept all</button>
    </div>

  </div>
  
</div>