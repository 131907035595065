@if (dataLoaded()) {

  <div class="h-full">
    <router-outlet></router-outlet>
  </div>

  @if (!hideCookiesBanner()) {
    <us2-cookies (cookiesAccepted)="onCookiesAccepted($event)"></us2-cookies>
  }

<!--  <div class="min-h-svh flex flex-col">-->

<!--    <div class="flex-1">-->
<!--      <router-outlet></router-outlet>-->
<!--    </div>-->

<!--&lt;!&ndash;    @if (!websiteNotPublishedYet) {&ndash;&gt;-->
<!--&lt;!&ndash;      <us2-footer></us2-footer>&ndash;&gt;-->
<!--&lt;!&ndash;    }&ndash;&gt;-->

<!--    @if (!hasAcceptedCookies()) {-->
<!--      <us2-cookies (cookiesAccepted)="onCookiesAccepted($event)"></us2-cookies>-->
<!--    }-->

<!--  </div>-->

} @else {
  <us2-progress-spinner></us2-progress-spinner>
}
