import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractInitDataService } from '@bc-core-lib';
import { ConfirmationDialogConfig } from '../dialogs/confirmation-dialog/confirmation-dialog-config';
import { ConfirmationDialogComponent } from '../dialogs/confirmation-dialog/confirmation-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class InitDataService extends AbstractInitDataService {
  constructor(
    private router: Router
  ) {
    super();
  }

  protected showErrorModal(title: string, errorMsg: string): void {
    const config: ConfirmationDialogConfig = {
      title,
      message: errorMsg,
      hideCancelBtn: true,
      confirmBtnLabel: $localize`Close`
    };

    this.dialog.open(ConfirmationDialogComponent, { data: { config } })
      .afterClosed()
      .subscribe(() => this.router.navigate([ '/' ]));
  }
}
