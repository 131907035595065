import { inject } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { CanActivateFn } from '@angular/router';
import { WebsiteService } from '@bc-core-lib';
import { filter, take } from 'rxjs/operators';

export const websiteGuard: CanActivateFn = (route, state) => {
  //const initDataService = inject(InitDataService);

  // todo do we need to return observable to guard
  //initDataService.initWebsite();

  const websiteService = inject(WebsiteService);

  return toObservable(websiteService.currentLoaded)
    .pipe(
      filter(isLoaded => isLoaded),
      take(1),
    );
};
